import React from "react"
import PropTypes from "prop-types"

import Container from "../components/common/Container"
import Layout from "../components/common/Layout"
import Link from "../components/common/Link"
import SEO from "../components/common/SEO"

const NotFoundPage = ({ uri }) => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1>404: Not found</h1>
      <p>
        We&apos;re sorry, but whatever you&apos;re looking for doesn&apos;t
        exist at {uri}.
      </p>
      <p>
        Try the footer links below or head back <Link to="/">home</Link>.
      </p>
    </Container>
  </Layout>
)

NotFoundPage.propTypes = {
  uri: PropTypes.string.isRequired,
}

export default NotFoundPage
